<template>
  <div class="box" >
    <div class="title">用户隐私保护政策</div>
    引言<br>
    雀诊互联网医院（以下简称“App”或“我们”）非常重视您的个人信息，并让您对个人信息拥有控制权，我们会使用强大的安全技术来保护您的隐私，同时制定严格的政策来管理所有个人信息及数据。当您访问雀诊 App时，或，使用我们提供的服务前，您需要同意本政策中关于我们如何收集、使用、储存和分享您的相关信息/数据的规定。如果您不同意本隐私政策中的任何内容，请立即停止使用或访问我们的产品和服务。<br>
    本隐私权政策旨在协助您了解我们以及我们使用的第三方SDK会收集哪些信息、为什么收集这些信息，以及您如何更新、管理、导出和删除自己的信息。<br>
    <br>
    术语<br>
    Cookie：Cookie 是包含字符串的小文件，会在您访问网站时发送到您的计算机上。当您再次访问网站时，Cookie 使该网站可以识别您的浏览器。Cookie 还能存储用户使用偏好及其他信息。您可以设置您的浏览器拒绝所有的 Cookie，或者在发送 Cookie 时提示。但如果没有 Cookie，某些网站功能或服务可能无法正常工作。<br>
    应用数据缓存：应用数据缓存是指设备上的一种数据存储机制。使用它有很多好处，例如，可让网络应用在未连接互联网的情况下运行，以及可通过提高内容加载速度来改善相关应用的性能。<br>
    个人信息：个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，如用户向我们提供的、可用于识别用户的个人身份的信息，例如姓名、电子邮件地址、电话号码、身份证号码、住址、通讯记录等信息，或我们可以正当地通过链接找到此类信息的其他数据，但不包括匿名化处理后的信息。<br>
    <br>
    IP地址：每台连接到互联网的计算机都指定了唯一的编号，称为互联网协议 (IP) 地址。由于这些编号通常都是根据国家/地区的区域指定的，因此 IP 地址通常可用于识别计算机连接至互联网时所在的国家/地区。<br>
    去标识化：去标识化指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。<br>

    一、我们如何搜集您的信息？<br>
    我们提供服务时，可能会收集、储存和使用下列信息：<br>
    您提供的信息<br>
    1.1为协助您注册、登录雀诊账号，提供您开启的雀诊全面的服务，您需要向我们提供账号名称、密码、手机号码、电子邮箱、或第三方平台账号信息（第三方平台授权登录，如微信、Apple登录等）等。如您需要使用某些特定功能（例如专家问诊、复诊随访等），您可能还需要向我们提供身份证号码、支付账号、个人地址信息等敏感个人信息以及您的昵称、头像、发布内容（包括文字、图片、视频等）、设备信息等个人信息。<br>
    在您使用雀诊的过程中，我们还会记录您的使用行为信息（例如您访问过的页面或者点击过的链接），并结合您的部分设备信息，用以评估您操作行为的安全风险，保障雀诊的稳定运行，并向您展示您可能感兴趣的信息。该等信息通常不能直接识别您的个人身份。<br>
    1.2 您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时上传、提交、存储、发送或接收的信息。<br>
    二、您在使用服务过程中，我们获取的信息<br>
    1.注册、登录：<br>
    1.1为接受雀诊全面的服务，您应首先注册一个用户账号，为完成创建账号，您需至少提供如下信息：您准备使用的账号名、密码、您本人的手机号码（用于身份验证），我们将通过发送短信验证码等方式来验证您的身份是否有效。您的账号名默认为系统随机为您生成的昵称。如您不注册雀诊账号，将通过第三方平台（微信、Apple登录等）授权登录，我们仍然需要绑定您的手机号码，通过手机号码来验证您的身份是否有效。<br>
    1.2如您拒绝提供手机号码进行身份验证，将导致注册不成功，并不可进行任何其它的具体操作或使用平台提供的核心服务等。<br>
    1.3向您提供产品和服务：<br>
    信息展示以及信息搜索浏览功能：雀诊平台提供丰富的医疗健康相关的信息，这些信息包含科普文章、患教知识等。为此，在您进行信息浏览时，我们可能会收集您在使用该等服务时的设备信息，包括设备名称、设备型号、操作系统版本和应用程序版本、语言设置、分辨率、电信运营商信息、浏览器类型等来为您提供信息内容展示的最优方式，我们也会为了不断改进和优化上述功能而使用您的上述信息。同时在您进行浏览时，还可根据个人需求和喜好选择搜索服务，通过搜索关键词精准找寻内容。此外，雀诊平台也将在未来不断拓展问诊、健康咨询、随访随诊等相关功能，雀诊将根据相应的功能拓展情况，不时更新此隐私政策。<br>
    为了提供该等个性化搜索喜好与内容的精准搜索并优化搜索服务以及依法留存网络服务日志，我们将可能收集您的日志信息作为网络日志保存，可能包括您输入的搜索关键词信息和点击的链接、您发布的评论、咨询的相关问题、对我们服务的反馈与评价、您上传的文字资料、图片、视频等信息以及您的IP地址、您访问服务的日期、时间、时长等。此类信息包括：<br>
    1.3.1设备信息：我们会收集设备专用的信息，例如您的硬件型号、操作系统信息、移动设备版本、设备识别码、屏幕分辨率等信息。<br>
    1.3.2日志信息：<br>
    1)设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码；<br>
    2)在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语、访问的页面url地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；<br>
    1.3.3 IP地址：<br>
    1)有关您曾使用的移动应用（APP）和其他软件的信息，以及您曾经使用该等移动应用和软件的信息；<br>
    2)您通过我们的服务进行通讯的信息，例如曾通讯的账号，以及通讯时间、数据和时长；<br>
    3)您通过我们的服务分享的内容所包含的信息（元数据），例如拍摄或上传的共享照片或录像的日期、时间或地点等。<br>
    1.3.4设备中的视频、图片等：<br>
    为了给您提供全方位的体验，经过您的授权，我们的产品会提取您设备中的视频、图片、软件名、版本号等，并通过加密上传到服务器进行存储和分析，用于匹配与该视频、图片、软件相对应的显示功能。<br>
    1.3.5 摄像头：<br>
    如您禁用摄像功能，可能无法使用我们的部分产品或服务，或在使用过程中达不到最佳效果或使用受到限制。我们不会利用摄像头采集您的个人照片，除非经您授权或您自行上传、提交、发布，我们不会主动采集您设备中的图片、视频信息。<br>
    1.3.6 位置信息：<br>
    您可以授权开启设备定位功能并使用我们基于位置提供的相关服务，当然，您也可以通过关闭定位功能随时停止我们对您的地理位置信息的收集。<br>
    2.客户服务与售后<br>
    当您向雀诊平台发起咨询、提起投诉、申诉、提供建议、进行评价或发起售后服务时，为了方便与您联系或帮助您解决问题，我们可能需要您提供姓名、手机号码、电子邮件或其他联系方式、订单信息等我们为您解决问题所必要的个人信息和基本信息。 如您拒绝提供上述信息，我们可能无法向您及时反馈投诉、申诉或咨询结果。<br>
    3.保障交易安全所必须的功能<br>
    为提高您使用我们的产品与/或服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账号安全，我们可能会通过了解您的浏览信息、订单信息、您常用的软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）； 我们也会收集您的设备信息对于雀诊系统问题进行分析、统计流量并排查可能存在的风险、在您选择向我们发送异常信息时予以排查。<br>
    4.个性化推送功能<br>
    我们会基于收集的信息，对您的偏好、习惯、位置等做出特征分析和用户画像，以便为您提供更适合的定制化服务，例如向您展现或推荐相关程度更高的搜索结果、信息流或者或您可能感兴趣的内容。为此，我们需要收集的信息包括您的设备信息、浏览器型号、日志信息、浏览记录等。<br>
    5.安全保障服务及第三方服务提供商<br>
    1）为方便您的访问并丰富您的体验，可能会有第三方提供的产品或服务。您可以选择是否访问这类内容或链接，或是否使用该第三方的产品或服务。但我们对于第三方提供的产品或服务没有控制权。我们无法控制第三方掌握的您的任何个人信息。您在使用第三方产品或服务过程中的信息保护问题，不适用于本政策的管理。本政策也不适用于您自行选择提供给第三方的任何信息。请您查看该第三方的隐私保护政策。<br>
    2）为了向您提供一键登录服务，我们的产品可能会集成第三方SDK或其他类似的应用程序，为了您的信息安全，我们已与第三方SDK服务商约定严格的数据安全保密义务，这些公司会严格遵守我们的数据隐私和安全要求。第三方服务商见附录。<br>
    6.我们获取的设备权限<br>
    为向您提供便捷、优质的服务，我们可能会调用您设备上的一些权限。在您使用相应功能时会看到弹窗提醒，询问您是否授权。您可以在客户端或设备的设置功能中选择关闭部分或全部权限。在不同设备中，上述权限的开启和关闭方式可能有所不同，具体请参考设备及系统开发方的说明或指引。<br>
    请您注意：上述附加功能可能需要您在您的设备中向我们开启您的相机（摄像头）、相册（图片库）、麦克风、系统通知等的访问权限，以实现这些功能所涉及的信息的收集和使用。我们在您首次使用该类附加功能需开启相应权限时，弹窗对您进行提示，你选择开启相应权限，则可进一步使用相应服务。同时您可以决定将这些权限随时的开启或关闭，您可以在您的设备系统中完成关闭设置。<br>
    请您注意：您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。<br>
    7.对于上述您的个人信息，大部分是由您主动向我们提供的，也有我们在您使用我们的产品或服务时通过Cookies、SDK及类似技术获取的，还有部分是从第三方间接获取的。我们会在下文中，向您展示说明。<br>
    <br>
    三、我们通过间接方式收集到的您的个人信息<br>
    当您选择通过微信、Apple等第三方平台登录使用我们的产品和/或服务时，我们可能从第三方处获取您授权共享的的账号信息（头像、昵称）、您的Apple ID等相关信息。<br>
    我们可能会从第三方间接收集（如共享等）您的个人信息，但这只发生在：我们确认该等第三方已经就将相关个人信息分享给我们已获得了您的同意，或者第三方依法被许可或被要求向我们披露您的个人信息。我们会要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任。我们会了解第三方已获得的个人信息处理的同意范围，包括使用目的，个人信息主体是否同意转让、共享、公开披露、删除等内容。<br>
    这些从第三方获取的个人信息将用于为您提供服务，以及用于保证我们所掌握的您的相关记录的准确性。如果我们对这些个人信息的处理活动超出第三方已获得的同意范围，在获取个人信息的合理期限内或处理个人信息前，我们会直接或通过提供信息的第三方征得您的同意。<br>
    若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先获得您的同意。<br>
    <br>
    四、我们自动收集的个人信息<br>
    当您通过网站、客户端、小程序等服务入口使用我们的服务时，我们可能自动从您的设备处收集您的IP地址、设备型号、唯一设备识别码、浏览器型号、粗略位置信息（例如国家或者城市信息）、安装的应用信息以及其他的设备技术信息，我们也会收集一些您使用我们服务的日志信息，例如访问过的页面或者点击过的链接。<br>
    收集该等信息主要为了保障服务的安全运行，也可以使我们更好地了解您，比如您来自哪里以及我们的服务有哪些吸引您的内容。我们将该等信息用于内部分析，以及为您提升服务质量和服务相关性（如向您推荐您可能会感兴趣的信息和内容）。我们同时也需要留存相应的日志来记录我们网站、移动客户端等服务入口的运行状态，以符合法律法规的规定。<br>

    五、无需征得您同意的情形<br>
    根据相关法律的规定，我们在以下情况下收集和使用您的个人信息无需取得您的同意：<br>
    1.为履行法定职责或者法定义务所必需；<br>
    2.为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；<br>
    3.为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理您的个人信息；<br>
    4.依照《个人信息保护法》规定在合理范围内处理您自行公开或者其他已经合法公开的个人信息；<br>
    5.法律、行政法规规定的其他情形。<br>

    六、个人敏感信息<br>
    我们收集和使用您的敏感个人信息的，将以弹窗等形式取得您的单独同意。在向平台在线提供任何属于敏感信息的个人信息前，请您清楚考虑这种提供是恰当的并且同意您的个人敏感信息可按本政策所述的目的和方式进行处理。 我们会在得到您的同意后收集和使用您的敏感信息以实现与平台在线业务相关的功能， 并允许您对这些敏感信息的收集与使用做出不同意的选择，但是拒绝使用这些信息会影响您使用相关功能。<br>
    如我们停止运营雀诊产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。<br>

    七、我们如何使用您的信息<br>
    我们可能将上述收集的信息用作以下用途向您提供服务:<br>
    1.在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；<br>
    2.帮助我们设计新服务，改善我们现有服务；<br>
    3.使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；<br>
    4.向您提供与您更加相关的广告以替代普遍投放的广告；<br>
    5.评估我们服务中的广告和其他促销及推广活动的效果，并加以改善；<br>
    6.执行软件验证、升级服务；<br>
    7.应用户特殊要求而提供特定服务时，需要将信息提供给我们的关联公司、第三方或其他用户；<br>
    8.其他有利于用户和雀诊 App运营者利益且不违反任何强制性法律法规的情况。<br>
    9.让您参与有关我们产品和服务的调查。<br>
    当我们要将信息用于本政策未载明的其他用途时，会事先征求您的同意，当我们要将基于特定目的收集而来的信息用于其他目的是，会事先征求您的同意。请你知悉，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。<br>
    <br>
    八、我们承诺<br>
    1.我们不会根据敏感类别（例如种族、宗教、性取向或健康状况）向您展示个性化广告；<br>
    2.我们不会与广告主分享可用于识别您个人身份的信息，例如您的姓名或电子邮件地址，除非经您授权同意，否则我们不会向任何人提供您的敏感个人信息；<br>
    3.在任何时候、任何情况下都不会向任何第三方出售您的个人信息，我们只会在法律允许的范围内使用根据本协议获得的信息。我们会制定严格的政策保护您的个人信息，不会将您的这些信息对外公开或向第三方提供。除非事先获得您的授权、本声明另有规定之外或出现如下情况：<br>
    1)相关法律法规、法律程序、政府机关的强制性要求；<br>
    2)为完成合并、分立、收购或资产转让而转移；<br>
    3)事先获得您的授权；<br>
    4)您使用共享功能；<br>
    5)以学术研究或公共利益为目的；<br>
    6)在法律法规和政策允许的范围内，为提升用户体验和提供个性化的推广服务；<br>
    7)我们认为必要且不违反法律强制性规定的其他情形。<br>
    <br>
    九、本政策的更新<br>
    1. 我们可能会不定期修改、更新本隐私政策，有关隐私政策的更新，我们会在雀诊 App中以通知公告等形式发布，您可以访问雀诊 App查询最新版本的隐私政策。<br>
    2、未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。<br>
    3、对于重大变更，我们可能还会提供更为显著的通知(包括对于某些服务，我们会通过电子邮件、站内信、短信、APP通知、弹窗等方式发送通知，说明隐私政策的具体变更内容)。<br>
    4、本政策重大变更包括但不限于：<br>
    (1)我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br>
    (2)我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；<br>
    (3)个人信息共享、转让或公开披露的主要对象发生变化；<br>
    (4)您参与个人信息处理方面的权利及其行使方式发生重大变化；<br>
    (5)个人信息安全影响评估报告表明存在高风险时。<br>
    5、若您不同意修改后的隐私政策，您有权并应立即停止使用雀诊服务。如果您继续使用雀诊平台的服务，则视为您接受雀诊对本隐私政策相关条款所做的修改。<br>
    <br>
    十、联系我们<br>
    如果您对个人信息保护问题有投诉、建议、疑问，您可以将问题发送至：uezhen.service@foryor.com  或邮寄至下列地址：宁夏银川市西夏区贺兰山路与兴州北街交汇处银川中关村创新中心2号楼第二层B-204。 我们将尽快审核所涉问题，并在验证您的用户身份后的十五个工作日内回复。感谢您对雀诊平台以及雀诊产品和服务的信任及使用！<br>
    <br>



    附录：雀诊第三方SDK收集使用信息说明<br>
    为雀诊的稳定运行或实现相关功能，我们可能会接入由第三方提供的软件开发包（SDK）实现前述目的。我们会尽到审慎义务，对合作方获取信息的软件工具开发包（SDK）进行严格的安全监测，以保护数据安全。<br>
    我们接入的第三方SDK主要服务于您以及其他的用户的需求，因此在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK。我们会及时在本说明中向您公开说明接入第三方SDK的最新情况。目前雀诊App接入的第三方SDK列明如下：<br>
    <br>
    华为<br>
    功能：用于向华为手机用户推送消息<br>
    收集个人信息类型：设备标识符<br>
    隐私权政策链接：https://consumer.huawei.com/cn/privacy/privacy-policy/<br>
    <br>
    小米<br>
    功能：用于向小米手机用户推送消息<br>
    收集个人信息类型：运营商信息、设备标识符<br>
    隐私权政策链接：https://dev.mi.com/console/doc/detail?pId=1822<br>
    <br>
    VIVO<br>
    功能：用于向VIVO手机用户推送消息<br>
    收集个人信息类型：运营商信息、设备标识符<br>
    隐私权政策链接：https://www.vivo.com.cn/about-vivo/privacy-policy<br>
    <br>
    OPPO<br>
    功能：用于向OPPO手机用户推送消息<br>
    收集个人信息类型：运营商信息、设备标识符<br>
    隐私权政策链接：https://security.oppo.com/cn/privacy.html<br>
    <br>
    友盟+sdk<br>
    功能：以提供统计分析服务，并通过地理位置校准报表数据准确性，提供基础反作弊能力<br>
    收集个人信息类型：Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息）<br>
    隐私权政策链接：https://www.umeng.com/page/policy<br>
    <br>
    极光推送SDK<br>
    功能：通过识别设备信息为App赋予推送能力，用于消息推送；地理位置和网络信息用于创建智能标签，实现区域、分群推送功能<br>
    收集个人信息类型：个人常用设备信息、位置信息、网络信息<br>
    <br>
    极光IM SDK<br>
    功能：通过识别设备信息为App赋予即时消息送达能力，用于即时消息；地理位置和网络信息用于创建智能标签，实现区域、分群即时通讯<br>
    收集个人信息类型：设备信息、地理位置、网络信息、用户聊天信息<br>
    隐私权政策链接：https://www.jiguang.cn/license/privacy<br>
    <br>
    实人认证SDK<br>
    功能：用于实现与用户的动作交互和照片采集，从而判断是否为真实用户操作、验证用户的身份，以及验证用户是否存在欺诈或作弊行为<br>
    收集个人信息类型：访问电话状态、读取外部存储空间的文件、写入外部存储权限以及访问摄像头权限，以用于实现与用户的动作交互和照片采集，从而判断是否为真实用户操作、验证用户的身份，以及验证用户是否存在欺诈或作弊行为。需要收集最终用户的设备信息（包括设备型号、操作系统版本、设备设置、MAC地址及IMEI、IDFA等设备识别符、设备环境等特征信息）<br>
    安全与合规链接：https://help.aliyun.com/document_detail/171604.html<br>


  </div>
</template>

<script>

export default {
  name: 'privacyAgreement',
  data() {
    return {

    }
  },
   components: {

   },
  created() {

  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.box{
  font-size: .26rem;
  padding: .3rem;
  .title{
    text-align: center;
    font-size: .28rem;
    font-weight: bold;
    line-height: .7rem;
  }
}
</style>